import Network from '@/services/network'
import {useQuery} from '@/services/utils/query'

export default function handleUser() {
    const network = new Network;
    const {allQuery} = useQuery()
    const fetchRoles = (companyId) => {
        return network.api('get', '/roles?company_id='+companyId);
    }
    const sendUserRequest = (data) => {
        return network.api('post', '/company/users/create', data);
    }
    const acceptCompanyRole = (data) => {
        return network.api('post', '/company/users/acceptance', data);
    }
    const userList = (companyId) => {
        return network.api('get', '/company/users/list?company_id='+companyId);
    }
    const updateUserRoleRequest = (data, userId) => {
        return network.api('post', `/company/users/${userId}/update`, data);
    }
    const resendUserMail = (data, userId) => {
        return network.api('post', `/company/users/resend-mail/${userId}`, data);
    }

    return {
        fetchRoles,
        sendUserRequest,
        allQuery,
        acceptCompanyRole,
        userList,
        resendUserMail,
        updateUserRoleRequest
    }
}