import Network from '@/services/network'
import {useQuery} from '@/services/utils/query'

export default function handleRolePermission() {
    const network = new Network;
    const {allQuery} = useQuery()

    const fetchPagePermissions = (query) => {
        return network.api('get', '/role/permissions/fetch-page-permissions' + query);
    }

    const storeUpdatePagePermissions = (data) => {
        return network.api('post', '/role/permissions/store-update-page-permissions', data);
    }
    const fetchUserRole = (query) => {
        return network.api('get', '/role/permissions/fetch-user-roles' + query);
    }
    return {
        allQuery,
        fetchPagePermissions,
        storeUpdatePagePermissions,
        fetchUserRole
    }
}